@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); /* font-family: "Poppins", sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap'); /* font-family: "Roboto Mono", monospace; */
@import url('https://fonts.googleapis.com/css2?family=Kadwa:wght@400;700&display=swap'); /* font-family: "Kadwa", serif; */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  background-color: #181818;
}
#sideBar {
  display: none;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  position: fixed;
  height: 100vh;
  width: 90vw;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 3;
}
#sideBar.active {
  transform: translateX(0);
}
#sideBar > .closeX {
  margin-top: 4vh;
  margin-left: 3vh;
  height: 3vh;

}
#sideBar > .links {
  display: flex;
  flex-direction: column;
  margin-top: 7vh;
  margin-left: 3vh;
}
#sideBar > .links > a {
  text-decoration: none;
  color: black;
  margin-bottom: 2vh;
  font-size: 3vh;
}
#sideBar > .cr {
  color: grey;
  margin-top: 3vh;
  margin-left: 1vh;
  font-size: 2vh;
}
#landing {
  height: 100vh;
  width: 100vw;
  position: relative;
}
#landing > #bg {
  opacity: 0;
  transition: opacity 1s ease;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}
#landing > #bg.active {
  opacity: 1;
}
#landing > .title {
  position: absolute;
  top: 30vh;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  width: 95vw;
}
#landing > .title > .name {
  font-size: 15vh;
  line-height: 18vh;
}
#landing > .title > .subTitle {
  font-size: 2vh;
  margin-top: 3vh;
  letter-spacing: 0.5vh;
  line-height: 4vh;
}
#landing > .topLeft {
  color: white;
  position: absolute;
  top: 3vh;
  left: 2vh;
  font-size: 1.4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#landing > .topLeft > .hamMenu {
  display: none;
  height: 2.5vh;
  margin-right: 2vh;
  cursor: pointer;
}
#fixedHeaderBar > img {
  display: none;
  height: 2.5vh;
  cursor: pointer;
}
.links {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 3.5vh;
  left: 50%;
  transform: translateX(-50%);
  width: 50vh;
}
.links > a {
  text-decoration: none;
  color: white;
  font-size: 1.4vh;
  position: relative;
}
.links > a > .bit {
  position: absolute;
  right: 0;
  top: -0.8vh;
  color: grey;
  font-size: 0.8vh;
  font-family: "Roboto Mono", monospace;
}

#landing > .scroll {
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#landing > .scroll > .dot {
  content: '';
  height: 0.5vh;
  width: 0.5vh;
  background-color: #CCCCCC;
  border-radius: 50%;
}
#landing > .scroll > .before {
  animation: bounceDown 3s ease infinite;
  margin-top: 0.7vh;
}
#landing > .scroll > .after {
  animation: bounceDown 3s ease infinite 0.2s;
  margin-top: 0.7vh;
}
#landing > .scroll > img {
  animation: bounceDown 3s forwards infinite 0.4s;
  margin-top: 0.7vh;
}
#fixedHeaderBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 8vh;
  background-color: #222222b4;
  z-index: 2;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
#fixedHeaderBar.active {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}
#sideBar > .links {
  position: relative;
  margin-top: 0;
  margin-left: 5vh;
  margin-bottom: 10vh;
  transform: translateX(0);
  left: unset;
}


@keyframes bounceDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2vh);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 800px) {
  #landing .links {
    left: unset;
    right: 4vh;
    transform: translateX(0);
    width: 50vh;
  }
}
@media screen and (max-width: 700px) {
  .links {
    display: none;
  }
  #fixedHeaderBar > .links {
    display: none;
  }
  #landing > .topLeft > .hamMenu {
    display: block;
  }
  #fixedHeaderBar > img {
    display: block;
    position: absolute;
    left: 3vh;
    top: 50%;
    transform: translateY(-50%);
  }
  #sideBar {
    display: block;
  }

  #landing > .title > .name {
    font-size: 10vh;
    line-height: 13vh;
  }
}
@media screen and (max-width: 430px) {
  #landing > .title > .name {
    font-size: 15vw;
    line-height: 20vw;
  }
}

.section {
  padding-bottom: 5vh;
  min-height: 80vh;
  width: 100vw;
}

/* My Expertise */
#expertise {
  margin-bottom: 10vh;
}
#expertise > .heading {
  color: white;
  font-size: 8vh;
  width: 100%;
  text-align: center;
  margin-top: 15vh;
}

#expertise > .skills {
  margin-top: 10vh;
  width: 100%;
  padding: 0 3vh;
  display: flex;
  justify-content: center;
  color: white;
  position: relative;
}
#expertise > .skills > .skill {
  border: 2px solid #a3a3a3;
}

#expertise > .skills > .square {
  height: 33vh;
  width: 36vh;
  flex:0 0 36vh;
  padding: 2vh 3vh;
  position: relative;
}

#expertise > .skills > .skill > .header {
  display: flex;
  align-items: flex-start;
}
#expertise > .skills > .skill > .header > img {
  height: 4vh;
}
#expertise > .skills > .skill > .header > .title {
  font-size: 2.8vh;
  margin-left: 2vh;
  line-height: 3.5vh;
  position: relative;
  height: fit-content;
}
#expertise > .skills > .skill > .header > .title > .design {
  position: absolute;
  top: 2.2vh;
  left: 0;
  height: 1vh;
  z-index: -1;
}
#expertise > .skills > .skill > .bottom {
  font-family: "Roboto Mono", monospace;
  font-size: 1.8vh;
}
#expertise > .skills > .skill > .bottom > p {
  color: #54595f;
}
#expertise > .skills > .skill > .bottom > p > p {
  color: white;
  margin-top: 0.6vh;
  margin-bottom: 1vh;
  margin-left: 2vh;
  padding-left: 2vh;
  border-left: 2px solid #54595f;
}

#expertise > .skills > .quote {
  display: none;
  padding-left: 6vh;
  position: relative;
}
#expertise > .skills > .quote > h2 {
  font-size: 2.5vh;
  margin-top: 2vh;
}
#expertise > .skills > .quote > p {
  color: #a6a6a6;
  font-size: 1.6vh;
  margin-top: 2vh;
}
#expertise > .skills > .quote > .design {
  position: absolute;
  top: 50%;
  width: 1vh;
  height: 70%;
  transform: translateY(-50%);
  left: 3vh;
  background-color: #b7f;
}
#expertise > .skills > img {
  position: absolute;
  left: 55%;
  bottom: 0vh;
  transform: translate(-50%, 95%);
  opacity: 0.2;
  width: 65vh;
  max-width: 80vw;
  z-index: -1;
}
@media screen and (max-width: 900px) {
  #expertise > .skills {
    flex-flow: row wrap;
  }
  #expertise > .skills > .square {
    flex-basis: 50%;
  }
  #expertise > .skills > .quote {
    display: block;
  }
  #expertise > .skills > img {
    left: 50%;
  }
}
@media screen and (max-width: 600px) {
  #expertise > .heading {
    font-size: 5vh;
  }
  #expertise > .skills > .square {
    flex-basis: 100%;
  }
  #expertise > .skills > .quote {
    display: none;
  }
}

/* My Work */
#work {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#work > .workHeading {
  padding: 2vh;
  color: white;
  max-width: 80vh;
}
#work > .workHeading > h1 {
  font-weight: bold;
  font-size: 10vh;
  line-height: 13vh;
  margin-bottom: 2vh;
}
#work > .workHeading > p {
  font-size: 2vh;
  font-weight: 200;
  margin-bottom: 5vh;
}

.projects {
  width: 85vh;
  display: flex;
  flex-wrap: wrap;
  gap: 5vh;
  margin-bottom: 10vh;
}

.featuredProject {
  height: 33vh;
  width: 100%;
  background-color: #252525;
  border-radius: 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.featuredProject > .Project {
  transform: translate(1vh, -1vh);
}
.featuredProject > .featuredProjectInfo {
  color: white;
  padding-left: 5vh;
}
.featuredProject > .featuredProjectInfo > h3 {
  font-size: 2.5vh;
  margin-bottom: -1vh;
  font-weight: 500;
}
.featuredProject > .featuredProjectInfo > h2 {
  font-size: 4vh;
  font-weight: 700;
  margin-bottom: 0vh;
}
.featuredProject > .featuredProjectInfo > p {
  font-size: 1.5vh;
  color: #969696;
}
.featuredProject > .featuredProjectInfo > .viewProjectButton {
  height: 5vh;
  width: 16vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5781C0;
  border-radius: 0.5vh;
  margin-top: 1.5vh;
  font-weight: 300;
  cursor: pointer;
}

.Project {
  height: 33vh;
  width: 40vh;
  background-color: #252525;
  border-radius: 3vh;
  overflow: hidden;
  cursor: pointer;
  color: white;
  position: relative;
  box-shadow: -0.5vh 0.5vh 0.5vh rgba(0, 0, 0, 0.47);
  transition: box-shadow 0.3s ease;
}
.Project:hover {
  box-shadow: -1vh 1vh 1vh rgba(0, 0, 0, 0.711);
}

.Project > .displayImage {
  width: 100%;
  height: 20vh;
  position: relative;
  overflow: hidden;
}
.Project > .displayImage > img {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Project > .projectInfo {
  padding: 2vh 3vh;
}
.Project > .projectInfo > h2 {
  font-family: "Kadwa", serif;
  font-size: 3vh;
  margin-bottom: -1.5vh;
}
.Project > .projectInfo > p {
  color: #969696;
  font-size: 1.5vh;
}
.Project > .projectInfo > .projectAction {
  position: absolute;
  bottom: 1vh;
  right: 2vh;
  display: flex;
  font-weight: 200;
  font-size: 1.5vh;
  letter-spacing: 0.1vh;

  opacity: 0.5;
  transform: translateY(20%);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.Project > .projectInfo > .projectAction > img {
  width: 2.5vh;
  margin-left: 1vh;
}
.Project:hover > .projectInfo > .projectAction {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 800px) {
  .projects {
    width: 45vh;
    max-width: 99%;
    align-items: center;
    flex-direction: column;
  }
  .featuredProject {
    flex-direction: column;
    height: fit-content;
    padding: 5vh 0 1vh 0;
  }
  .featuredProjectInfo {
    width: 40vh;
    margin-bottom: 5vh;
  }
  .featuredProject > .Project {
    transform: translate(0, 0);
  }
  .Project {
    max-width: 99%;
  }
}

/* Footer */
footer#contact {
  background-color: #0d0d0d;
  min-height: 70vh;
  width: 100%;
  color: white;
  padding: 5vh 10vh;
  display: flex;
  justify-content: space-between;
  position: relative;
}
footer#contact > .footerMessage {
  width: 35vw;
}
footer#contact > .footerMessage > h1 {
  font-size: 6vh;
  font-weight: bold;
}
footer#contact > .footerMessage > p {
  margin-top: 3vh;
  font-size: 2.5vh;
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
}
footer#contact > .footerLinks {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  width: 40vw;
}
footer#contact > .footerLinks > .email {
  font-size: 3vh;
  width: fit-content;
  position: relative;
  z-index: 1;
}
footer#contact > .footerLinks > .email > .colorDesign {
  width: 105%;
  height: 0.8vh;
  position: absolute;
  bottom: 1vh;
  left: -0.3vh;
  background-color: #844EC9;
  z-index: -1;
}
footer#contact > .footerLinks > .contactLinks > .link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 4vh;
  font-size: 3vh;
  margin: 2vh;
  cursor: pointer;
  width: fit-content;
  position: relative;
}
footer#contact > .footerLinks > .contactLinks > .link > .hoverer {
  position: absolute;
  top: 50%;
  right: -8vh;
  transform: translateY(-50%);
  width: 5vh;
  height: 0.8vh;
  background-color: white;

  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}
footer#contact > .footerLinks > .contactLinks > .link:hover > .hoverer {
  opacity: 1;
}
footer#contact > .footerLinks > .contactLinks > .link > img {
  height: 100%;
  margin-right: 2vh;
}
footer#contact > .copyright {
  position: absolute;
  bottom: 5vh;
  left: 10vh;
  font-size: 2vh;
}
@media screen and (max-width: 1000px) {
  footer#contact {
    flex-direction: column;
    justify-content: flex-start;
    padding: 2vh 5vh;
  }
  footer#contact > .footerMessage {
    width: 80vw;
  }
  footer#contact > .footerLinks {
    width: 80vw;
    margin-top: 5vh;
    margin-bottom: 10vh;
  }
  footer#contact > .footerMessage > h1 {
    font-size: 4vh;
  }
  footer#contact > .footerMessage > p {
    margin-top: 1vh;
    font-size: 2vh;
  }
  footer#contact > .footerLinks > .email {
    font-size: 2.5vh;
  }
  footer#contact > .footerLinks > .email > .colorDesign {
    width: 102%;
    height: 0.6vh;
    bottom: 0.5vh;
    left: -0.2vh;
  }
  
}

/* SCROLLBAR */
*::-webkit-scrollbar {
    width: 1vh; 
    height: 1.2vh;
  }
  *::-webkit-scrollbar-track {
    background-color: #080707;
    cursor: pointer;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #454545;
    border-radius: 10px;
    cursor: pointer; 
  }
  *::-webkit-scrollbar-thumb:active {
    cursor: pointer;
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: #7e7e7e; 
  }